import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
const Card = styled.div`
  background-color: #000000;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const ImageContainer = styled.div`
  width: 15rem;
  max-height: 21rem;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
`;

const Rating = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: "DM Sans";
  font-style: normal;
  border: 2px solid ${({ color }) => color};
  border-radius: 4rem;
  padding: 0.25rem 0.75rem;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  margin-top: 1rem;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;

  font-family: "DM Sans";
  font-style: normal;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`;

const InfoTag = styled(Tag)`
  color: ${({ color }) => color};
`;

const InfoTagSeparator = styled.span`
  color: ${({ color }) => color};
  margin-top: 2px;
  opacity: 0.75;
`;

const CategoryTag = styled(Tag)`
  color: rgba(255, 255, 255, 0.5);
`;

const CategoryTagSeparator = styled.span`
  color: rgba(255, 255, 255, 0.5);
  margin-top: 2px;
`;

const Description = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const Button = styled.a`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.75rem;
  background: ${({ color }) => color};
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  gap: 0.625rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.125rem;

  transition: all 0.2s ease-in-out;

  color: ${({ color }) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? "#000000" : "#FFFFFF";
  }};
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
    transform: scale(0.95);
`;

function MovieTvShowCard({ item }) {
  const tags = [
    item.basicInfo.parsed.releaseYear
      ? item.basicInfo.parsed.releaseYear
      : null,
    item.basicInfo.parsed.duration ? item.basicInfo.parsed.duration : null,
    item.basicInfo.parsed.ageRating ? item.basicInfo.parsed.ageRating : null
  ];

  const sourceBrandColor = item.basicInfo.sourceBrandColor
    ? item.basicInfo.sourceBrandColor
    : "#ffffff";

  return (
    <Card>
      <ImageContainer>
        <Image
          src={item.basicInfo.parsed.images.openGraph.url}
          alt={item.text}
        />
      </ImageContainer>
      <InfoContainer>
        <Info>
          {tags.filter(Boolean).length > 0 && (
            <Tags>
              {tags.filter(Boolean).map((category, index, array) => (
                <React.Fragment key={category}>
                  <InfoTag color={sourceBrandColor}>{category}</InfoTag>
                  {index < array.length - 1 && (
                    <InfoTagSeparator color={sourceBrandColor}>
                      ·
                    </InfoTagSeparator>
                  )}
                </React.Fragment>
              ))}
            </Tags>
          )}
          <Title>
            {item.basicInfo.movieName
              ? item.basicInfo.parsed.movieName
              : item.basicInfo.parsed.tvShowName
              ? item.basicInfo.parsed.tvShowName
              : item.basicInfo.parsed.title}
          </Title>
          {item.basicInfo.description && (
            <Description>{item.basicInfo.parsed.description}</Description>
          )}
          {item.basicInfo.categories &&
            item.basicInfo.categories.length > 0 && (
              <Tags>
                {item.basicInfo.parsed.categories
                  .filter(Boolean)
                  .map((category, index, array) => (
                    <React.Fragment key={category}>
                      <CategoryTag>{category}</CategoryTag>
                      {index < array.length - 1 && (
                        <CategoryTagSeparator>·</CategoryTagSeparator>
                      )}
                    </React.Fragment>
                  ))}
              </Tags>
            )}
          {(item.basicInfo.parsed.stars ||
            item.basicInfo.parsed.stars !== 0) && (
            <Rating color={sourceBrandColor}>
              <FontAwesomeIcon
                icon={fas["faStar"]}
                width="0.75rem"
                style={{ marginBottom: "1px", marginLeft: "-1px" }}
              />
              {item.basicInfo.parsed.stars}
            </Rating>
          )}
        </Info>
        {item.basicInfo.parsed.source && (
          <Button href={item.text} target="_blank" color={sourceBrandColor}>
            <FontAwesomeIcon
              icon={fas["faExternalLinkAlt"]}
              width="1rem"
              style={{ marginTop: "1px" }}
            />
            View on {item.basicInfo.parsed.source}
          </Button>
        )}
      </InfoContainer>
    </Card>
  );
}

export default MovieTvShowCard;
