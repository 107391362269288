import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
  InternalButton,
  ButtonText,
  ConfirmButton,
  CancelButton
} from "../components/InternalButtons";

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

const PagesList = styled.ul`
  list-style: none;
  padding: 0;
`;

const PagesListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoadingContainer = styled.div``;

function Index({
  user,
  pages,
  createNewPage,
  deletePage,
  onLogout,
  idToken,
  loading
}) {
  const [pageToDelete, setPageToDelete] = useState(null);

  useEffect(() => {
    document.title = "Page Builder";
  }, []);

  const handleCreateNewPageClick = async () => {
    const newPageId = await createNewPage();
    window.location.href = `/page/${newPageId}?edit=true`;
  };

  const handleDeleteClick = (pageId) => {
    setPageToDelete(pageId);
  };

  const confirmDelete = () => {
    if (pageToDelete) {
      deletePage(pageToDelete);
      setPageToDelete(null);
    }
  };

  const cancelDelete = () => {
    setPageToDelete(null);
  };

  return (
    <>
      <h1>Welcome, {user.displayName}</h1>
      <Buttons>
        <InternalButton onClick={onLogout}>
          <ButtonText>
            <FontAwesomeIcon icon={fas["faSignOut"]} />
            Logout
          </ButtonText>
        </InternalButton>
        <InternalButton $variant="secondary" onClick={handleCreateNewPageClick}>
          <ButtonText>
            <FontAwesomeIcon icon={fas["faPlus"]} />
            Create New Page
          </ButtonText>
        </InternalButton>
      </Buttons>
      <h2>Your Pages</h2>
      {loading ? (
        <LoadingContainer>
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </LoadingContainer>
      ) : pages.length === 0 ? (
        <p>You don't have any pages yet.</p>
      ) : (
        <PagesList>
          {pages.map((page) => (
            <PagesListItem key={page.id}>
              <h3>
                <Link to={`/page/${page.id}?edit=true`}>{page.title}</Link>
              </h3>
              <Buttons>
                {pageToDelete === page.id ? (
                  <>
                    <ConfirmButton onClick={confirmDelete}>
                      <ButtonText>
                        <FontAwesomeIcon icon={fas["faCheck"]} />
                        Confirm
                      </ButtonText>
                    </ConfirmButton>
                    <CancelButton onClick={cancelDelete}>
                      <ButtonText>
                        <FontAwesomeIcon icon={fas["faTimes"]} />
                        Cancel
                      </ButtonText>
                    </CancelButton>
                  </>
                ) : (
                  <InternalButton onClick={() => handleDeleteClick(page.id)}>
                    <ButtonText>
                      <FontAwesomeIcon icon={fas["faTrash"]} />
                      Delete
                    </ButtonText>
                  </InternalButton>
                )}
              </Buttons>
            </PagesListItem>
          ))}
        </PagesList>
      )}
    </>
  );
}

export default Index;
