import React from "react";
import styled from "styled-components";

const Card = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1rem;
`;

const TextContent = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #000;
`;

function Text({ item }) {
  return (
    <Card>
      <TextContent>{item.input}</TextContent>
    </Card>
  );
}

export default Text;
