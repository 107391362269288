import React from "react";
import styled from "styled-components";
import TabButton from "./TabButton";

const TabGroupLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const TabGroup = ({ activeTab, click }) => {
  return (
    <TabGroupLayout>
      <TabButton
        label="Link"
        icon="faLink"
        $isActive={activeTab === "link"}
        clickHandler={() => {
          click("link");
        }}
      />
      <TabButton
        label="Text"
        icon="faFont"
        $isActive={activeTab === "text"}
        clickHandler={() => {
          click("text");
        }}
      />
      <TabButton
        label="Upload"
        icon="faPhotoFilm"
        $isActive={activeTab === "upload"}
        clickHandler={() => {
          click("upload");
        }}
      />
    </TabGroupLayout>
  );
};

export default TabGroup;
