import React from "react";

import LocationCard from "../../PreviewCards/LocationCard";
import MovieTvShowCard from "../../PreviewCards/MovieTvShowCard";
import VideoCard from "../../PreviewCards/VideoCard";
import BookCard from "../../PreviewCards/BookCard";
import ArticleCard from "../../PreviewCards/ArticleCard";
import RecipeCard from "../../PreviewCards/RecipeCard";
import WebsiteCard from "../../PreviewCards/WebsiteCard";

const Link = ({ item }) => {
  const renderCard = () => {
    switch (item.linkType) {
      case "LOCATION":
        return <LocationCard item={item} />;
      case "MOVIE_TV_SHOW":
        return <MovieTvShowCard item={item} />;
      case "VIDEO":
        return <VideoCard item={item} />;
      case "BOOK":
        return <BookCard item={item} />;
      case "ARTICLE":
        return <ArticleCard item={item} />;
      case "RECIPE":
        return <RecipeCard item={item} />;
      case "WEBSITE":
        return <WebsiteCard item={item} />;
      default:
        return <WebsiteCard item={item} />;
    }
  };

  return renderCard();
};

export default Link;
