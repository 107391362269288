import React from "react";
import styled from "styled-components";

const Debug = styled.pre`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  margin: 0;
  white-space: pre-wrap;
`;

const DebugRow = styled.div`
  display: flex;
`;

const Label = styled.span`
  font-weight: bold;
  opacity: 0.5;
  width: 10rem;
  flex-shrink: 0;
  word-break: break-all;
`;

const Value = styled.span`
  font-weight: normal;
  flex-grow: 1;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
`;

function DebugSection({ item }) {
  return (
    <Debug>
      <DebugRow>
        <Label>type</Label>
        <Value>{item.type}</Value>
      </DebugRow>
      {item.type === "TEXT" && (
        <DebugRow>
          <Label>input</Label>
          <Value>{item.input}</Value>
        </DebugRow>
      )}
      {item.type === "LINK" && (
        <>
          <DebugRow>
            <Label>linkType</Label>
            <Value>{item.linkType}</Value>
          </DebugRow>
          {typeof item.basicInfo?.original === "object" && (
            <>
              <DebugRow>
                <Label>ORIGINAL DATA</Label>
              </DebugRow>
              {Object.entries(item.basicInfo.original).map(([label, value]) => (
                <DebugRow key={label}>
                  <Label>{label}</Label>
                  <Value>
                    {typeof value === "object" ? JSON.stringify(value) : value}
                  </Value>
                </DebugRow>
              ))}
            </>
          )}
          {typeof item.basicInfo?.parsed === "object" && (
            <>
              <DebugRow>
                <Label>PARSED DATA</Label>
              </DebugRow>
              {Object.entries(item.basicInfo.parsed).map(([label, value]) => (
                <DebugRow key={label}>
                  <Label>{label}</Label>
                  <Value>
                    {typeof value === "object" ? JSON.stringify(value) : value}
                  </Value>
                </DebugRow>
              ))}
            </>
          )}
        </>
      )}
    </Debug>
  );
}

export default DebugSection;
