import React from "react";
import Link from "./Types/Link";
import Text from "./Types/Text";
import Media from "./Types/Media";

const Item = ({ item }) => {
  if (!item) {
    return null;
  } else if (item.type === "TEXT") {
    return <Text item={item} />;
  } else if (item.type === "MEDIA") {
    return <Media item={item} />;
  } else if (item.type === "LINK") {
    if (!item.linkType) {
      return <div>Determining type...</div>;
    }

    if (!item.basicInfo?.parsed) {
      return <div>Loading basic info...</div>;
    }

    return <Link item={item} />;
  }
};

export default Item;
