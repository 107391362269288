import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const hexToRgba = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 1rem;
  background-color: ${(props) => props.$colorLight};
  color: ${(props) => props.$colorDark};
  border-color: ${(props) => {
    return hexToRgba(props.$colorDark, 0.1);
  }};
  font-family: "DM Sans", sans-serif;

  ::selection {
    background-color: ${(props) => props.$colorDark};
    color: ${(props) => props.$colorLight};
  }
`;

const LocationContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

const CategoryHeading = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  text-align: center;
  margin: 0;
  padding-top: 0.125rem;
  line-height: 120%;
`;

const PlaceNameHeading = styled.h2`
  font-weight: 800;
  font-size: 2rem;
  line-height: 120%;
  text-align: center;
  margin-bottom: 1rem;
  margin: 0;
`;

const Address = styled.p`
  font-size: 0.875rem;
  line-height: 130%;
  padding: 0.125rem 0 0.33rem 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.25rem;
  margin: 0;
`;

const StarContainer = styled.div`
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  line-height: 120%;
`;

// const StarNumber = styled.div`
//   font-size: 0.75rem;
//   font-weight: 700;
//   margin: 0;
// `;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 0.75rem;
  height: 0.75rem;
  padding-bottom: 0.133rem;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.75rem 0.75rem 0.75rem;
  gap: 0.75rem;
`;

const ImageContainer = styled.div`
  width: 50%;
  height: 12rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.$colorDark};
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0px 0px 1px
      ${(props) => {
        return props.$colorDark
          ? hexToRgba(props.$colorDark, 0.2)
          : "rgba(0, 0, 0, 0.15)";
      }};
    pointer-events: none;
    z-index: 2;
    border-radius: 0.5rem;
    z-index: 2;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
`;

const MapImage = styled(Image)`
  mix-blend-mode: luminosity;
`;

const WeatherContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
  height: 1.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => hexToRgba(props.$colorDark, 0.75)};
  padding: 0.125rem 0.75rem;
  border-radius: 0.25rem;
  color: ${(props) => props.$colorLight};
`;

const WeatherEmoji = styled.div`
  font-size: 1.25rem;
`;

const WeatherCondition = styled.div`
  font-size: 1rem;
  text-transform: capitalize;
`;

const getWeatherData = async ({ latitude, longitude }) => {
  const weatherUrl = `https://xbinwbuc7bnkqonzu3kx654jsi0lcgrs.lambda-url.us-east-1.on.aws/?location=${latitude},${longitude}`;
  const response = await fetch(weatherUrl);
  const data = await response.json();
  return data;
};

function LocationCard({ item }) {
  const [weatherData, setWeatherData] = useState({});

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const geocodeData = item.basicInfo.parsed.geocodeData;
        if (geocodeData.features && geocodeData.features.length > 0) {
          const [longitude, latitude] =
            geocodeData.features[0].geometry.coordinates;

          const weatherData = await getWeatherData({ latitude, longitude });
          setWeatherData(weatherData);
        }
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };

    fetchWeatherData();
  }, [item]);

  return (
    <Card
      $colorLight={item.basicInfo.parsed.colorsFromCategory?.light || "white"}
      $colorDark={item.basicInfo.parsed.colorsFromCategory?.dark || "black"}
    >
      <LocationContainer>
        <CategoryHeading>{item.basicInfo.parsed.category}</CategoryHeading>
        <PlaceNameHeading>{item.basicInfo.parsed.placeName}</PlaceNameHeading>
        <Address>
          {item.basicInfo.parsed.address}, {item.basicInfo.parsed.city},{" "}
          {item.basicInfo.parsed.state} {item.basicInfo.parsed.zip}
        </Address>
        <StarContainer>
          {/* <StarNumber>{item.basicInfo.parsed.numStars}</StarNumber> */}
          {[...Array(item.basicInfo.parsed.totalStars)].map((_, index) => (
            <StyledFontAwesomeIcon
              key={index}
              icon={
                index < item.basicInfo.parsed.numStars
                  ? fas["faStar"]
                  : far["faStar"]
              }
            />
          ))}
        </StarContainer>
      </LocationContainer>

      <ImagesContainer>
        <ImageContainer>
          <Image
            src={item.basicInfo.parsed.images.openGraph.url}
            alt={item.text}
          />
        </ImageContainer>
        <ImageContainer
          $colorDark={item.basicInfo.parsed.colorsFromCategory?.dark}
        >
          <WeatherContainer
            $colorLight={
              item.basicInfo.parsed.colorsFromCategory?.light || "white"
            }
            $colorDark={
              item.basicInfo.parsed.colorsFromCategory?.dark || "black"
            }
          >
            {weatherData.emoji ? (
              <>
                <WeatherEmoji>{weatherData.emoji}</WeatherEmoji>
                <WeatherCondition>{weatherData.condition}</WeatherCondition>
              </>
            ) : (
              <div>Loading weather...</div>
            )}
          </WeatherContainer>
          {item.basicInfo.parsed.images.staticMap.url && (
            <MapImage
              src={item.basicInfo.parsed.images.staticMap.url}
              alt="Location Map"
            />
          )}
        </ImageContainer>
      </ImagesContainer>
    </Card>
  );
}

export default LocationCard;
