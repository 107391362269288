import React, { useState } from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firebase";

export const handleLogout = () => {
  auth
    .signOut()
    .then(() => {
      // Handle successful logout
    })
    .catch((error) => {
      console.error("Error during logout:", error);
    });
};

export function Auth({ onLogin, onLogout, setUser }) {
  const [error, setError] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLoginClick = async () => {
    if (isLoggingIn) return;
    setIsLoggingIn(true);
    setError(null);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      onLogin();
    } catch (error) {
      console.error("Error during login:", error);
      setError(error.message);
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div>
      <h1>Pages</h1>
      <button onClick={handleLoginClick} disabled={isLoggingIn}>
        {isLoggingIn ? "Logging in..." : "Login"}
      </button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}
