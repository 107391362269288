import React from "react";
import styled from "styled-components";

const Card = styled.div``;

function ArticleCard({ item }) {
  return (
    <Card>
      <h3>Article: {item.text}</h3>
      {/* Add more article-specific details here */}
    </Card>
  );
}

export default ArticleCard;
