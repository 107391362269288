import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

const Card = styled.a`
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.colorDark};
  background-color: ${(props) => props.colorLight};
  font-family: "DM Sans", sans-serif;
  border: 1px solid rgba(27, 10, 0, 0.1);
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid rgba(27, 10, 0, 0.2);
    box-shadow: 0px 6px 20px 0px rgba(27, 10, 0, 0.08);
    transform: translateY(-0.15rem);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 21rem;
  position: relative;
`;

const TypeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: rgba(255, 253, 247, 0.8);
  border-radius: 0.25rem;
  border: 0;
  color: #1b0a00;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 16rem;
  object-fit: cover;
  object-position: center;
`;

const Website = styled.a`
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1.125rem;
  text-decoration: none;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem 2rem 2rem 2rem;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
`;

const Tag = styled.p`
  font-family: "Inter";
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;

  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
`;

const Description = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const CuisineFlag = styled.p`
  font-size: 1.5rem;
  line-height: 120%;
  margin: 0;
`;

function RecipeCard({ item }) {
  console.log(item);
  return (
    <Card
      href={item.input}
      colorLight={item.basicInfo.parsed.colors.light}
      colorDark={item.basicInfo.parsed.colors.dark}
    >
      {/* <h3 style={{"color": "white"}}>Recipe: {item.text}</h3> */}
      <ImageContainer>
        <Image
          src={item.basicInfo.parsed.images.openGraph.url}
          alt={item.text}
        />
        <TypeIcon>
          <FontAwesomeIcon
            icon={fas.faUtensils}
            style={{ width: "1rem", height: "1rem" }}
          />
        </TypeIcon>
      </ImageContainer>
      <InfoContainer>
        <Website>{item.basicInfo.parsed.source}</Website>
        <Title>{item.basicInfo.parsed.title}</Title>
        <Tags>
          <CuisineFlag>{item.basicInfo.parsed.flagEmojiOfCuisine}</CuisineFlag>
          <Tag>{item.basicInfo.parsed.cuisine}</Tag>
        </Tags>
        <Description>{item.basicInfo.parsed.description}</Description>
      </InfoContainer>
      {/* <ImageContainer>
        <Image src={item.basicInfo.parsed.images.openGraph.url} alt={item.text} />
      </ImageContainer>
      <InfoContainer>
        <Info>
          <Title>{item.text}</Title>
          <Tags>
            {item.basicInfo.parsed.tags.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </Tags>
          <Description>{item.basicInfo.parsed.description}</Description>
        </Info>
        <Button color={item.basicInfo.parsed.sourceBrandColor}>
          View Recipe
        </Button>
      </InfoContainer> */}
    </Card>
  );
}

export default RecipeCard;
