import React, { useState } from "react";
import styled from "styled-components";
import TabGroup from "../Tabs/TabGroup";
import PreviewBar from "./PreviewBar";
import ComposerForm from "./ComposerForm";
import { createOrUpdateItem } from "../../actions/Pages";
import { getType, getBasicInfo } from "../../actions/Items";

const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0.5rem 1.25rem 0 0.5rem;
  overflow: hidden;

  background-color: #ffffff;
  border-radius: 1rem 1rem 0 0;
  box-shadow: var(--box-shadow--default);

  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: calc(800px - 2rem + 6px);
  z-index: 1000;
`;

const Composer = ({ idToken, pageId, onItemAdded, onItemUpdated }) => {
  const [activeTab, setActiveTab] = useState("link");

  const handleSubmit = async (input) => {
    if (input.trim()) {
      try {
        if (activeTab === "link") {
          const newItem = await createOrUpdateItem(idToken, pageId, null, {
            type: "LINK",
            input
          });

          onItemAdded({ ...newItem, status: "success" });

          const typeData = await getType(input);

          if (typeData && newItem.id) {
            const updatedItemData = { ...newItem, ...typeData };

            const savedItem = await createOrUpdateItem(
              idToken,
              pageId,
              newItem.id,
              updatedItemData
            );

            onItemUpdated({ ...savedItem, status: "success" }, newItem.id);
          }

          const basicInfoData = await getBasicInfo(typeData.linkType, input);

          if (basicInfoData && newItem.id) {
            const updatedItemData = { ...newItem, ...basicInfoData };

            const savedItem = await createOrUpdateItem(
              idToken,
              pageId,
              newItem.id,
              updatedItemData
            );

            onItemUpdated({ ...savedItem, status: "success" }, newItem.id);
          }
        } else if (activeTab === "text") {
          const newItem = await createOrUpdateItem(idToken, pageId, null, {
            type: "TEXT",
            input
          });

          onItemAdded({ ...newItem, status: "success" });
        }
      } catch (error) {
        console.error("Error creating or updating item:", error);
        onItemAdded({ input, status: "error" });
      }
    }
  };

  return (
    <CardLayout>
      <TabGroup activeTab={activeTab} click={setActiveTab} />
      <ComposerForm activeTab={activeTab} onSubmit={handleSubmit} />
      <PreviewBar $isEmpty={true} items={[]} />
    </CardLayout>
  );
};

export default Composer;
