import styled from "styled-components";

export const InternalButton = styled.button`
  border: 0;
  outline: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
`;

export const ButtonText = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ConfirmButton = styled(InternalButton)`
  background-color: #ff4136;
  color: white;
`;

export const CancelButton = styled(InternalButton)`
  background-color: #aaaaaa;
  color: white;
`;

export const SaveButton = styled(InternalButton)`
  background-color: #2ecc40;
  color: white;
`;
