import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

const Card = styled.a`
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: #313033;
  font-family: "DM Sans", sans-serif;
  border: 1px solid rgba(27, 10, 0, 0.1);
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid rgba(27, 10, 0, 0.2);
    box-shadow: 0px 6px 20px 0px rgba(27, 10, 0, 0.08);
    transform: translateY(-0.15rem);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 21rem;
  position: relative;
`;

const TypeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: rgba(255, 253, 247, 0.8);
  border-radius: 0.25rem;
  border: 0;
  color: #1b0a00;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 16rem;
  object-fit: cover;
  object-position: center;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 2.25rem 3rem 3rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Website = styled.p`
  font-family: "DM Sans", sans-serif;
  font-weight: 900;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  text-align: center;
`;

const Channel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-size: 0.8rem;
  font-weight: 800;
  padding: 0;
  margin: 0 0 0.5rem;
  text-transform: uppercase;
  color: #b780ff;
  text-align: center;

  p {
    margin: 0;
  }
`;

const VideoCard = ({ item }) => {
  return (
    <Card href={item.input} target="_blank">
      <ImageContainer>
        <Image
          src={item.basicInfo.parsed.images.openGraph.url}
          alt={item.text}
        />
        <TypeIcon>
          <FontAwesomeIcon
            icon={fas.faPlay}
            style={{ width: "1rem", height: "1rem" }}
          />
        </TypeIcon>
      </ImageContainer>
      <InfoContainer>
        <Website>{item.basicInfo.parsed.source}</Website>
        <Channel>
          <FontAwesomeIcon
            icon={fas.faUserCircle}
            style={{ marginRight: "0.5rem" }}
          />
          <p>{item.basicInfo.parsed.channelName}</p>
        </Channel>
        <Title>{item.basicInfo.parsed.title}</Title>
      </InfoContainer>
    </Card>
  );
};

export default VideoCard;
