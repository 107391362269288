import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

const Card = styled.a`
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem 0;
  color: ${(props) => props.colorDark};
  background-color: ${(props) => props.colorLight};
  font-family: "Inter", serif;
  border: 1px solid rgba(27, 10, 0, 0.1);
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid rgba(27, 10, 0, 0.2);
    box-shadow: 0px 6px 20px 0px rgba(27, 10, 0, 0.08);
    transform: translateY(-0.15rem);
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  width: 40%;
`;

const Image = styled.img`
  /* width: 100%; */
  height: 325px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0 2rem 0 0;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: 400;
  font-family: "DM Serif Display", serif;
  margin: 0;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Tag = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
`;

const Description = styled.p`
  flex: 1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const WebsiteLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const WebsiteLink = styled.span`
  font-family: "DM Sans", sans-serif;
  font-weight: 900;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  /* color: rgba(27, 10, 0, .5); */
`;

const BookCard = ({ item }) => {
  return (
    <Card
      href={item.input}
      target="_blank"
      rel="noopener noreferrer"
      colorLight={item.basicInfo.parsed.colors.light}
      colorDark={item.basicInfo.parsed.colors.dark}
    >
      <ImageContainer>
        <Image
          src={item.basicInfo.parsed.images.openGraph.url}
          alt={item.basicInfo.parsed.title}
        />
      </ImageContainer>
      <InfoContainer>
        <WebsiteLinkContainer>
          <FontAwesomeIcon
            icon={fas.faBook}
            style={{
              width: "1.5rem",
              height: "1.5rem",
              marginRight: "0.75rem"
            }}
          />
          <WebsiteLink>{item.basicInfo.parsed.source}</WebsiteLink>
        </WebsiteLinkContainer>
        <Title>{item.basicInfo.parsed.title}</Title>
        <TagContainer>
          <Tag>{item.basicInfo.parsed.author}</Tag>
          <Tag>•</Tag>
          <Tag>{item.basicInfo.parsed.genre}</Tag>
        </TagContainer>
        <Description>{item.basicInfo.parsed.description}</Description>
      </InfoContainer>
    </Card>
  );
};

export default BookCard;
