import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Composer from "../components/Composer/Composer";
import DebugSection from "../components/Debug";
import Item from "../components/Items/Item";
import {
  fetchItems,
  deleteItem,
  fetchPage,
  updatePageMetadata,
  updatePageItemOrder
} from "../actions/Pages";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  InternalButton,
  ButtonText,
  ConfirmButton,
  CancelButton,
  SaveButton
} from "../components/InternalButtons";

const EditControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const EditButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const Items = styled.div`
  padding-bottom: 10rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TitleInput = styled.input`
  font-size: 2rem;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  width: 100%;
  font-family: "Inter", sans-serif;
  padding: 0.5rem 0;
  margin: 1rem 0;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  border: none;
  outline: none;
  width: 100%;
  padding: 0.5rem 0;
  margin: 1rem 0;
  border-bottom: 2px solid #fff;
`;

function Page({ idToken, user }) {
  const { pageId } = useParams();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const bottomRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  const prevItemsRef = useRef();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showDebug, setShowDebug] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (page) {
      document.title = `${page.title}`;
    } else {
      document.title = "Loading...";
    }
  }, [page]);

  const loadItems = async () => {
    try {
      setLoading(true);
      const fetchedPage = await fetchPage(idToken, pageId);
      setPage(fetchedPage);
      const fetchedItems = await fetchItems(idToken, pageId);

      const sortedItems = [...fetchedItems].sort((a, b) => {
        return fetchedPage.itemOrder[a.id] - fetchedPage.itemOrder[b.id];
      });

      setItems(sortedItems);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching page and items:", err);
      setError("Failed to load page and items");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldScroll) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  useEffect(() => {
    if (prevItemsRef.current) {
      const newDataAdded = items.some((item, index) => {
        const prevItem = prevItemsRef.current[index];
        return (
          prevItem &&
          ((!prevItem.type && item.type) ||
            (!prevItem.linkType && item.linkType) ||
            (!prevItem.basicInfo && item.basicInfo) ||
            (!prevItem.description && item.description))
        );
      });
      if (newDataAdded) {
        setShouldScroll(true);
      }
    }
    prevItemsRef.current = items;
  }, [items]);

  const handleAddItem = (newItem) => {
    setItems((prevItems) => [...prevItems, newItem]);
    setShouldScroll(true);
  };

  const handleUpdateItem = (updatedItem, itemId) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, ...updatedItem } : item
      )
    );
  };

  const handleDeleteClick = (itemId) => {
    setItemToDelete(itemId);
  };

  const handleConfirmDelete = async () => {
    if (!idToken) {
      console.error("No ID token available");
      setError("Unable to delete item: No authentication token");
      return;
    }
    try {
      await deleteItem(idToken, pageId, itemToDelete);
      setItems(items.filter((item) => item.id !== itemToDelete));
      setItemToDelete(null);
    } catch (err) {
      console.error("Error deleting item:", err);
      setError("Failed to delete item");
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const debugParam = urlParams.get("debug");
    setShowDebug(debugParam === "true");
    const editParam = urlParams.get("edit");
    setIsEditMode(editParam === "true");
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (event.key === "d") {
      setShowDebug((prevShowDebug) => !prevShowDebug);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleEditTitle = () => {
    setIsEditingTitle(true);
    setEditedTitle(page?.title || "");
  };

  const handleSaveTitle = async () => {
    if (!idToken) {
      console.error("No ID token available");
      setError("Unable to save title: No authentication token");
      return;
    }
    try {
      await updatePageMetadata(idToken, pageId, { title: editedTitle });
      setPage({ ...page, title: editedTitle });
      setIsEditingTitle(false);
    } catch (err) {
      console.error("Error saving title:", err);
      setError("Failed to save title");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    const newOrder = {};
    newItems.forEach((item, index) => {
      newOrder[item.id] = index;
    });

    setPage((prevPage) => ({
      ...prevPage,
      itemOrder: newOrder
    }));

    setItems(newItems);

    const updateItemOrder = async () => {
      if (!idToken) {
        console.error("No ID token available");
        setError("Unable to update item order: No authentication token");
        return;
      }
      try {
        await updatePageItemOrder(idToken, pageId, newOrder);
      } catch (err) {
        console.error("Error updating item order:", err);
        setError("Failed to update item order");
      }
    };

    updateItemOrder();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const isAuthenticated = !!idToken;
  const isOwner = user?.uid === page?.userId;
  const showEditControls = isAuthenticated && isOwner && isEditMode;

  return (
    <>
      {showEditControls && (
        <EditControls>
          <Link to="/">&larr; back</Link>
          <EditButtons>
            <InternalButton
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_URL}/page/${pageId}`
                );
                setShowCopied(true);
                setTimeout(() => {
                  setShowCopied(false);
                }, 2000);
              }}
            >
              {showCopied ? (
                <ButtonText>
                  <FontAwesomeIcon icon={fas["faCheck"]} />
                  Copied
                </ButtonText>
              ) : (
                <ButtonText>
                  <FontAwesomeIcon icon={fas["faCopy"]} />
                  Copy Link
                </ButtonText>
              )}
            </InternalButton>
            <InternalButton
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_URL}/page/${pageId}`,
                  "_blank"
                );
              }}
            >
              <ButtonText>
                <FontAwesomeIcon icon={fas["faEye"]} />
                Preview
              </ButtonText>
            </InternalButton>
            <InternalButton
              onClick={() => {
                setShowDebug((prevShowDebug) => !prevShowDebug);
                const newUrl = new URL(window.location.href);
                if (!showDebug) {
                  newUrl.searchParams.set("debug", "true");
                } else {
                  newUrl.searchParams.delete("debug");
                }
                window.history.pushState({}, "", newUrl);
              }}
            >
              <ButtonText>
                <FontAwesomeIcon icon={fas["faBug"]} />
                {showDebug ? "Disable Debug" : "Enable Debug"}
              </ButtonText>
            </InternalButton>
          </EditButtons>
        </EditControls>
      )}
      <TitleContainer>
        {isEditingTitle ? (
          <>
            <TitleInput
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
            <SaveButton onClick={handleSaveTitle}>
              <ButtonText>
                <FontAwesomeIcon icon={fas["faCheck"]} />
                Save
              </ButtonText>
            </SaveButton>
          </>
        ) : (
          <>
            <Title>{page?.title}</Title>
            {showEditControls && (
              <InternalButton onClick={handleEditTitle}>
                <ButtonText>
                  <FontAwesomeIcon icon={fas["faPencil"]} />
                  Edit
                </ButtonText>
              </InternalButton>
            )}
          </>
        )}
      </TitleContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <Items {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={!showEditControls}
                >
                  {(provided) => (
                    <ItemContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {showEditControls && (
                        <Buttons>
                          {itemToDelete === item.id ? (
                            <>
                              <ConfirmButton onClick={handleConfirmDelete}>
                                <ButtonText>
                                  <FontAwesomeIcon icon={fas["faCheck"]} />
                                  Confirm
                                </ButtonText>
                              </ConfirmButton>
                              <CancelButton onClick={handleCancelDelete}>
                                <ButtonText>
                                  <FontAwesomeIcon icon={fas["faTimes"]} />
                                  Cancel
                                </ButtonText>
                              </CancelButton>
                            </>
                          ) : (
                            <>
                              <InternalButton
                                onClick={() => handleDeleteClick(item.id)}
                              >
                                <ButtonText>
                                  <FontAwesomeIcon icon={fas["faTrash"]} />
                                  Delete
                                </ButtonText>
                              </InternalButton>
                              <ButtonText>
                                <FontAwesomeIcon icon={fas["faGripLines"]} />
                                Reorder
                              </ButtonText>
                            </>
                          )}
                        </Buttons>
                      )}
                      <Item item={item} />
                      {showDebug && <DebugSection item={item} />}
                    </ItemContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div ref={bottomRef} />
            </Items>
          )}
        </Droppable>
      </DragDropContext>
      {showEditControls && (
        <Composer
          idToken={idToken}
          pageId={pageId}
          onItemAdded={handleAddItem}
          onItemUpdated={handleUpdateItem}
        />
      )}
    </>
  );
}

export default Page;
